exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-digitalization-js": () => import("./../../../src/pages/digitalization.js" /* webpackChunkName: "component---src-pages-digitalization-js" */),
  "component---src-pages-engineering-js": () => import("./../../../src/pages/engineering.js" /* webpackChunkName: "component---src-pages-engineering-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-materials-science-js": () => import("./../../../src/pages/materials-science.js" /* webpackChunkName: "component---src-pages-materials-science-js" */),
  "component---src-pages-notifications-js": () => import("./../../../src/pages/notifications.js" /* webpackChunkName: "component---src-pages-notifications-js" */),
  "component---src-pages-quality-control-js": () => import("./../../../src/pages/quality-control.js" /* webpackChunkName: "component---src-pages-quality-control-js" */)
}

